.nav-bar {
  display: flex;
  height: 80px !important;
  padding: 15px;
  justify-content: center;
}

.nav-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1050px;
}

.nav-bar .logo {
  height: 40px;
  cursor: pointer;
}

.nav-link p {
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px;
  color: #27384c;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.nav-link p:hover {
  color: #ff00a2;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .nav-bar {
    height: auto;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-bar-content {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
  .nav-link {
    margin: 0 auto;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .nav-bar .logo {
    height: 30px;
  }
}
