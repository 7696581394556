.landing-page .header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f7f7f7;
}

.landing-page .header .header-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.landing-page .header .header-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  display: block;
}

.landing-page .header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.landing-page .header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  grid-gap: 50px;
  z-index: 0;
}

.landing-page .header-content .header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.landing-page .ant-collapse {
  font-family: 'Inter', sans-serif;
}

.landing-page .section.gray {
  background: #fafafa;
}

.landing-page .section.black {
  background: black;
}

.landing-page .black .title,
.landing-page .black .big-subtitle {
  color: white;
}

.width-for-img {
  max-width: 850px !important;
}

.landing-page .title {
  font-size: 38px;
  line-height: 1.2;
  font-weight: 700;
  color: #2a3e50;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.8px;
  /* text-transform: uppercase; */
}

.landing-page .title.white {
  color: white;
}

.landing-page .subtitle.white,
.landing-page .label.white {
  color: white;
}

.landing-page.title.center-aligned {
  text-align: center;
}

.landing-page .subtitle {
  font-size: 18px;
  line-height: 1.6;
}

.landing-page .header-content .text .ant-btn-primary {
  margin-top: 20px;
}

.landing-page .header-content .img img {
  height: 550px;
}

.landing-page .section {
  display: grid;
  width: 100%;
  padding: 80px 30px;
  align-items: center;
  justify-content: center;
}

.landing-page .section.white {
  background: white;
}

.landing-page .section.dark-gray,
.landing-page .section.dark-gray .title,
.landing-page .section.dark-gray .big-subtitle {
  background: rgb(51, 51, 51);
  color: white;
}

.landing-page .section.blue,
.landing-page .guarantee-section.blue {
  background: #2b3a4b;
}

.landing-page .guarantee-section {
  display: flex;
  width: 100%;
  max-width: 1050px;
  justify-content: space-between;
  padding: 35px;
  border-radius: 20px;
  align-items: center;
  gap: 30px;
}

.landing-page .guarantee-section img {
  height: 200px;
}

.landing-page .guarantee-section .text-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.landing-page .guarantee-section .guarantee-card {
  padding: 14px 24px;
  background: #30465f;
  border-radius: 20px;
}

.landing-page .guarantee-section .guarantee-card p {
  color: white;
  font-size: 14px;
  line-height: 22px;
}

.landing-page .section .benefits {
  width: 100%;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.landing-page .section .benefits-info {
  display: flex;
  align-items: flex-start;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  text-align: left;
}

.benefit-image {
  width: 100%;
  height: 280px;
  object-fit: contain;
  background-color: rgb(240, 242, 255);
}

.what-you-get {
  display: grid;
  gap: 20px;
  align-items: center;
  padding: 20px;
  justify-content: center;
}

.what-you-get-item {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  grid-gap: 80px;
}

.what-you-get-item.even {
  flex-direction: row-reverse;
}

.what-you-get-item .text {
  width: 100%;
  max-width: 450px;
}

.what-you-get-item img {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.container {
  display: flex;
  justify-content: center;
  grid-gap: 80px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}

.how-it-works-card {
  padding: 24px;
  border-radius: 0 0 10px 10px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.how-it-works-card .subtitle {
  text-align: left !important;
}

.centered-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 80px 30px;
  background: white;
}

.max-width-container {
  max-width: 1200px;
  width: 100%;
}

.number-of-workouts-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.number-of-workouts-container .title {
  color: white;
}

.number-of-workouts-container .paragraph {
  width: 100%;
  max-width: 500px;
  color: white;
}

.number-of-workouts-container .text-section {
  margin-left: 40px;
  width: 100%;
  max-width: 500px;
}

.number-of-workouts-container .link-container {
  display: flex;
  align-items: center;
  color: blue;
  font-size: 15px;
  margin-top: 10px;
}

.pricing-container {
  display: flex;
  justify-content: center;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.05);
  grid-gap: 30px;
  align-items: center;
}

.pricing-container p,
.pricing-container h4 {
  color: white;
}

.pricing-container img {
  height: 500px;
  margin: 0 auto;
}

.pricing-items {
  display: flex;
  align-items: center;
}

.pricing-items .button {
  padding: 3px 12px;
  background-color: red;
  color: white;
  font-weight: 800;
  border-radius: 50px;
  font-size: 14px;
  margin: 0 20px;
  text-align: center;
}

.pricing-items .actual-price {
  text-decoration: line-through;
  color: #98a1a8 !important;
  font-weight: 800;
  font-size: 35px;
}

.pricing-items .discounted-price {
  font-weight: 800;
  font-size: 36px;
}

.full-width-button {
  width: 100% !important;
}

.landing-button.black {
  background-color: black;
}

.landing-button.black:hover {
  background-color: black !important;
}

.landing-button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #1f63e0 !important;
}

.landing-button.gradient {
  background: linear-gradient(135deg, var(--primary), var(--accent));
  transition: background 0.5s ease-out, transform 0.5s ease-out,
    box-shadow 0.5s ease-out;
  color: white !important;
  border: none;
}

.landing-button.gradient:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
}

.landing-button.gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.4)
  );
  transition: left 0.5s ease-out;
}

.landing-button.gradient:hover::before {
  left: 0;
}

.landing-page.title.center-aligned {
  text-align: center;
}

.content {
  padding: 50px 50px 50px 50px;
  overflow: auto;
  min-height: calc(100vh - 200px);
}

.transformation-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

.what-you-get-item .transformation-img,
.transformation-container .transformation-img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.program-title {
  white-space: pre-line;
  max-width: 800px;
}

.hero {
  position: relative;
  height: calc(100vh - 180px);
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: white;
  text-align: center;
}

.hero img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.8;
  z-index: 1;
}

.hero .hero-content {
  position: relative;
  z-index: 2;
  color: white;
}

.hero p {
  width: 100%;
  max-width: 800px;
  font-size: 60px;
  line-height: 80px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
}

.header-reviews {
  display: flex;
  margin-top: 30px;
}

.highlighted-block {
  margin-bottom: 20px;
  max-width: 400px;
}

.subtitle.text-gradient.bold {
  line-height: 24px;
}
.landing-page .framework-img {
  max-height: 500px;
}

.mobile-button-wrapper {
  display: none;
}

.total-workouts-icon {
  font-size: 100px;
  position: relative;
  animation: pulse 3s infinite ease-in-out;
}

.total-workouts-icon::after {
  content: '';
  position: absolute;
  /* top: -5px; */
  right: 20px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  top: 50px;
}

.total-workouts-icon::before {
  content: '';
  position: absolute;
  /* top: -7px; */
  right: 18px;
  width: 14px;
  height: 14px;
  border: 2px solid red;
  border-radius: 50%;
  opacity: 0;
  animation: ripple 2s infinite;
  top: 48px;
}

@media (max-width: 1024px) {
  .transformation-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .number-of-workouts-container .text-section {
    max-width: 100%;
    padding: 20px 0px;
  }

  .landing-page .section .benefits-info {
    display: grid;
  }
  .mobile-button-wrapper {
    display: block;
    margin-top: 10px;
    text-align: center;
  }
  .landing-page .framework-img {
    width: 100%;
  }

  .transformation-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .transformation-img {
    height: auto;
    margin-bottom: 20px;
  }
  .landing-page .header {
    height: auto;
    padding: 30px 30px 30px 30px;
  }
  .landing-page .header-content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .landing-page .title {
    font-size: 32px;
    line-height: 40px;
  }
  .landing-page .header-content .img img {
    height: 400px;
    margin-bottom: 0px;
  }
  .number-of-workouts-container {
    display: grid;
    text-align: center;
  }
  .number-of-workouts-container .text-section {
    margin-left: 0px;
  }
  .number-of-workouts-container .link-container {
    justify-content: center;
  }
  .what-you-get-item .subtitle {
    text-align: center;
  }
  .what-you-get-item,
  .what-you-get-item.even {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 20px;
  }
  .what-you-get-item .small-title {
    text-align: center;
  }
  .what-you-get-item .text {
    width: 100%;
    max-width: 350px;
    text-align: center;
  }
  .pricing-items {
    display: grid;
    justify-content: center;
    grid-gap: 5px;
    margin-top: 0px;
  }
  .pricing-container {
    display: grid;
    grid-gap: 0px;
  }
  .pricing-container img {
    max-height: 300px;
  }
  .list-container .subtitle {
    display: grid;
    align-items: start;
    grid-template-columns: 10% 90%;
  }
  .landing-page .content {
    padding: 0px;
  }
  .landing-page .how-it-works-card .title {
    line-height: 32px;
    margin-top: 15px;
  }
  .landing-page .section {
    padding: 60px 30px;
  }
  .landing-page .section .benefits {
    grid-template-columns: 1fr;
  }
  .transformation-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .hero p {
    font-size: 32px;
    padding: 0px 20px;
    line-height: 50px;
    font-weight: 700;
  }
  .landing-page .header-content .img {
    margin-bottom: 20px;
  }
  .landing-page .header-content .text {
    margin-right: 0px;
  }
  .header .program-title {
    text-align: center;
  }

  .header .text-gradient.big-subtitle {
    text-align: center;
  }
  .header-content .ant-btn {
    width: 100%;
  }
  .header-reviews {
    justify-content: center;
  }
  .highlighted-block {
    text-align: center;
    margin-bottom: 30px;
  }
  .landing-button span {
    font-size: 16px;
  }
  .landing-page .subtitle {
    font-size: 18px;
    line-height: 26px;
  }
  .header-container .header-container-title {
    text-align: center;
  }
  .header-container .big-subtitle {
    text-align: center;
  }
  .landing-page .header-content {
    grid-gap: 20px;
  }
  .landing-page .guarantee-section {
    display: grid;
    justify-items: center;
  }
  .landing-page .guarantee-section .text-content {
    text-align: center;
  }
  .total-workouts-icon::after {
    right: 150px;
    top: 48px;
  }

  .total-workouts-icon::before {
    right: 148px;
    top: 46px;
  }
}

.offer-badge {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #ff0066; /* Brand color for labels */
  color: white;
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 0.8em;
  white-space: nowrap; /* Keeps the text on one line */
  z-index: 10; /* Ensure it's above other elements */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.offer-content {
  text-align: center;
}

.offer-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 700;
}

.offer-description {
  font-size: 1em;
  margin-bottom: 20px;
}

.offer-warning {
  font-weight: bold;
}

/* Responsive design */
@media (max-width: 768px) {
  .offer-badge {
    top: auto; /* Remove the top positioning */
    bottom: 100%; /* Position it above the card */
    right: 10px; /* Maintain a consistent spacing from the right */
    transform: translateY(
      10px
    ); /* Push it down a bit so it's not sticking to the card edge */
    font-size: 0.7em; /* Adjust the font size for smaller screens if necessary */
  }

  .offer-title {
    font-size: 1.4em; /* Adjust the title size for smaller screens */
    padding-top: 20px; /* Add padding to ensure the title does not go under the badge */
  }
}

keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Reduced scaling to make it less intense */
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
