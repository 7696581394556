.order-summary {
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 30px 0px 30px 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
}

.meals {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
  border-radius: 1px solid black;
  padding-bottom: 50px;
}

.global-card.clickable:hover {
  cursor: pointer;
}

.global-card {
  padding: 34px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.global-card.blue {
  background-color: #2b3a4b;
}

.global-card.blue p {
  color: white;
}

.global-card.dark {
  background-color: var(--card-bg);
  color: white;
}

.global-card.dark .small-title,
.global-card.dark .subtitle {
  color: #dcdcdc;
}

.global-card.pricing-card {
  padding: 45px 34px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 50px 0px 50px 0px;
  border: 2px solid black;
  max-width: 450px;
}

.global-card.pricing-card.blue-border {
  border: 2px solid #2761f1;
}

.global-card,
.order-detail-card {
  padding: 34px;
  border-radius: 10px;
  background-color: white;
  /* box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%); */
}

.global-card.bordered {
  border: 1px solid #d3d3d3;
}

.global-card .nutrition-info {
  display: flex;
  justify-content: space-around;
  padding: 15px 0px 30px 0px;
}

.global-card .header {
  display: flex;
  align-items: center;
}

.header.title {
  width: 100%;
  max-width: 180px;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-container .manage-meal-qty-btn btn {
  width: 50% !important;
  height: 50% !important;
}

.email-form-container {
  display: grid;
  grid-gap: 10px;
}

.order-container {
  margin-top: 15px;
}

.btn-container .status-btn {
  margin-top: 20px;
}

.btn-container .status-btn.active {
  background: green;
  color: white;
}

.btn-container .status-btn.inactive {
  background: red;
  color: white;
}

.meal-ing-container .meal-ing-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 10px;
  grid-gap: 5px;
}

.ant-btn.ant-btn-circle.ant-btn-primary.manage-meal-qty-btn {
  height: 46px !important;
  width: 46px !important;
  border-radius: 50%;
}

/* @media (max-width: 768px) {
  .global-card {
    box-shadow: none;
  }
} */
