.form-container {
  padding: 24px;
  background: #f5f5f5;
}

.tool-heading-container {
  width: 100%;
  height: auto;
  /* background: url('../../assets/images/HeaderBackground.png') no-repeat center
    center;
  background-size: cover;
  box-sizing: border-box; */
}

.tool-heading-content {
  display: grid;
  padding: 20px;
  grid-template-columns: 40% 60%;
  gap: 80px;
  width: 100%;
  max-width: 1250px;
  min-height: calc(100vh - 80px);
  box-sizing: border-box;
  align-items: center;
  margin: 0 auto;
}

.tool-heading-container img {
  max-height: 550px;
  width: auto;
  object-fit: contain;
}

.step-text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  color: #ff00a2;
  text-decoration: underline;
}

.macros-progress {
  display: flex;
  justify-content: space-between;
}

.custom-macros-input-container {
  display: flex;
  grid-gap: 5px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #dce0ff;
}

.questions-container {
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
  padding: 40px;
}

.ant-select-item-option-content {
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}

.results-container {
  padding: 50px 15px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
}

.macro-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.macro-meal-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-step {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2761f1;
  text-align: center;
}

.error-text {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.swap-options.gradient-border-left {
  position: relative;
  padding-left: 10px;
}

.swap-options.gradient-border-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(135deg, var(--primary), var(--accent));
}

.food-img {
  border-radius: 50%;
  height: auto;
  height: 50px;
}

.magic-shuffle-button {
  background: linear-gradient(135deg, var(--primary), var(--accent));
  color: var(--text-light);
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.magic-shuffle-button:hover {
  transform: scale(1.05);
}

.magic-shuffle-button:active {
  animation: shake 0.5s;
}

.food-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.4rem;
}

.food-title {
  font-size: 1.125rem;
  color: var(--text-light);
}

.food-stats {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 48px;
  font-size: 18px;
}

.action-button--primary {
  border: 2px solid var(--primary);
  color: var(--primary);
}

.action-button--secondary {
  border: 2px solid var(--secondary);
  color: var(--secondary);
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.swap-button {
  border-radius: 50px;
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  cursor: pointer;
  height: 24px;
  color: var(--text-light);
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s ease;
}

.swap-button:hover {
  background: #393939; /* Slightly brighter on hover */
  transform: translateY(-1px);
}

.back-button {
  color: var(--text-dark);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 18px;
}

.macro-card {
  background: linear-gradient(135deg, var(--primary), var(--accent));
  border-radius: 0.75rem;
  padding: 1.5rem;
  color: var(--text-light);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.landing-button.generate-meal-plan {
  color: white !important;
  background: transparent;
  border: 2px solid var(--primary);
  transition: all 0.3s ease;
}

.landing-button.generate-meal-plan:hover {
  color: white;
  background-color: transparent !important;
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .tool-heading-content {
    display: block;
    height: auto;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px 10px 50px;
    justify-items: flex-start;
  }

  .tool-heading-container img {
    max-height: 400px;
    width: auto;
    object-fit: contain;
  }
  .tool-heading-content h1 {
    font-size: 36px;
  }
  .tool-heading-container {
    background: none;
  }
  /* .macros-progress {
    margin-bottom: 30px;
  } */
  .questions-container {
    padding: 30px 15px;
  }
  .ant-select-item-option-content {
    max-width: 200px;
  }
  .label {
    text-align: left;
  }
  .results-container {
    padding: 30px 15px;
    min-height: auto;
  }
  .results-container .global-card.dark {
    padding: 20px;
  }
  .custom-macros-input-container {
    display: grid;
  }
  .tool-heading-content .title-container {
    padding: 20px 20px 30px;
  }
  .landing-button.generate-meal-plan {
    width: 100%;
  }
  .food-item {
    display: grid;
    justify-content: space-between;
    align-items: center;
  }
  .swap-button {
    justify-content: center;
    margin-top: 5px;
  }
}

@keyframes valueChangeEffect {
  0% {
    transform: scale(1);
    color: #fff;
  }
  50% {
    transform: scale(1.2);
    color: #dce0ff;
  }
  100% {
    transform: scale(1);
    color: #fff;
  }
}

.macro-value-change {
  animation: valueChangeEffect 0.5s ease-out;
}
